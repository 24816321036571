import { error } from '@/services/Log'
import type { Product, ProductVariant } from '@/types/SearchAPI/SAPIProduct'
import type { HeapTrackingPageType } from '@/types/ThirdPartyIntegrations/Heap'
import type { NextIncomingMessage } from 'next/dist/server/request-meta'
import allPromisesWithRetries from './allPromisesWithRetries'

type StoredCustomerInfo = { customerId: string | number, customerEmail: string }
export const getCustomerDetails = (): StoredCustomerInfo => {
  try {
    const customerDetails = localStorage.getItem('customer')
    if (customerDetails) {
      const { customerId, customerEmail } = JSON.parse(customerDetails) as StoredCustomerInfo
      if ((
        typeof customerId === 'number' || typeof customerId === 'string'
      ) && (
        typeof customerEmail === 'string'
      )) {
        return {
          customerId,
          customerEmail,
        }
      }
    }
  } catch (err) {
    error('Failed to get customer details from localStorage')
  }
  return {
    customerId: 0,
    customerEmail: '',
  }
}

type ProductClickGaInput = {
  product: Product,
  position: unknown,
  category: string
}

const kebabCase = (str: string) => (
  `${str || ''}`.replace(/([a-z])([A-Z])/g, '$1-$2') // get all lowercase letters that are near to uppercase ones
    .replace(/[\s_]+/g, '-') // replace all spaces and low dash
    .toLowerCase()
)

export const productClickGa = ({ product, position, category }: ProductClickGaInput) => {
  const sendToGA = () => {
    if (typeof window !== 'undefined') {
      const customer = getCustomerDetails()
      const customerId = customer?.customerId
      const customerEmail = customer?.customerEmail
      const visitorType = customerEmail ? 'Logged In' : 'Guest'
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: 'productClick',
        ecommerce: {
          visitorType,
          customerId,
          click: {
            actionField: { list: kebabCase(category) },
            products: [{
              name: product.name,
              id: product.variants[0].sku,
              price: product.calculated_price,
              brand: product.brand,
              category,
              variant: product.variants[0].id,
              position,
            }],
          },
        },
        customerEmail,
      })
    }
  }
  if (window.loadedAndInteractive) {
    sendToGA()
  } else {
    window.addEventListener('loadedAndInteractive', sendToGA, { once: true })
  }
}

export const productUpdateGA = (
  lineItem: {
    name: string
    sku: string
    originalPrice: number
    salePrice: number
    variantId: number
    quantity: number
    brand: string | number
  },
  event: 'addToCart' | 'removeFromCart',
  cartTotal: number,
) => {
  const sendToGA = () => {
    try {
      const customer = getCustomerDetails()
      const customerId = customer?.customerId
      const customerEmail = customer?.customerEmail
      const visitorType = customerEmail ? 'Logged In' : 'Guest'
      const keyName = event === 'addToCart' ? 'add' : 'remove'
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
      window?.dataLayer?.push({
        event,
        ecommerce: {
          visitorType,
          customerId,
          [keyName]: {
            products: [{
              name: lineItem.name,
              // for grouped products like quilts, do you have a default that you can use?
              id: lineItem.sku,
              // for grouped products like quilts, do you have a default that you can use?
              price: lineItem.originalPrice,
              // for grouped products like quilts, do you have a default that you can use?
              salePrice: lineItem.salePrice,
              brand: lineItem.brand,
              variant: lineItem.variantId, // "Small" | "Large" | etc
              quantity: lineItem.quantity, // passing quantity from product update
            }],
          },
          customerEmail,
        },
        cartTotal,
      })
    } catch (err) {
      error('Failed to send GA event', err)
    }
  }
  if (window.loadedAndInteractive) {
    sendToGA()
  } else {
    window.addEventListener('loadedAndInteractive', sendToGA, { once: true })
  }
}

export const getCustomerOrderCount = async () => {
  const orderCountFromStorage = sessionStorage.getItem('customerOrderCount')
  if (orderCountFromStorage !== null && orderCountFromStorage) {
    return parseInt(orderCountFromStorage)
  }
  const [
    { getCustomer },
    { getCustomerOrders },
  ] = await allPromisesWithRetries(() => [
    import('@/helpers/graphql'),
    import('@/services/Orders'),
  ])
  const { customer, success } = await getCustomer()
  if (!success || !customer) {
    return 0
  }
  const orderCount = (await getCustomerOrders({ sort: 'date_created:desc' })).length
  sessionStorage.setItem('customerOrderCount', `${orderCount}`)
  return orderCount
}

export type GAPageType = 'home' | 'category' | 'product' | 'cart' | 'searchresults' | 'checkout' | 'purchase' | 'other' | 'account'

export const customerHeap = async (pageType: HeapTrackingPageType) => {
  const customer = getCustomerDetails()
  const customerId = customer?.customerId
  const customerEmail = customer?.customerEmail

  const [
    orderCount,
    [{ default: identify }],
  ] = await Promise.all([
    getCustomerOrderCount(),
    allPromisesWithRetries(() => [import('@/services/HeapAnalytics/identifyHeapCustomer')]),
  ])
  identify({
    visitorType: customerId ? 'Logged In' : 'Guest',
    customerId,
    customerEmail,
    customerOrderCount: orderCount,
    pageType,
  })
}

export const formatPrice = (price: string | number | undefined, digit = 2) => {
  // using parseFloat to make sure this function works for both number and string
  const floatPrice = parseFloat(`${price || ''}`)
  if (!Number.isFinite(floatPrice)) {
    return price
  }
  return `$${parseFloat(`${price || ''}`).toFixed(digit)}`
}

// using parseFloat to make sure this function works for both number and string
export const formatPriceRange = (minPrice: string | number | undefined, maxPrice: string | number | undefined, digit = 2) => `${formatPrice(minPrice, digit) || ''} - ${formatPrice(maxPrice, digit) || ''}`

type PriceTypeType = 'calculated_price' | 'price' | 'sale_price'
export const getPriceOrPriceRange = (product: Product, priceType: PriceTypeType) => {
  const { variants } = product
  const sortedVariantPrices = variants?.map((variant) => parseFloat(`${variant[priceType]}`))?.sort()
  const minPrice = variants ? sortedVariantPrices[0] : product[priceType]
  const maxPrice = variants ? sortedVariantPrices.pop() : product[priceType]

  return (
    (minPrice === maxPrice)
      ? formatPrice(minPrice)
      : formatPriceRange(minPrice, maxPrice)
  )
}

export const sizeArray = ['small', 'medium', 'large', 'extra small', 'extra large', 'long', 'tall', 'short']

export const checkVariantNameIsSize = (el: ProductVariant) => {
  const name = el.option_values[0].label.toLowerCase()
  if (/^\d/.test(name) || sizeArray.includes(name)) {
    return true
  }
  return false
}

export const priceValue = (price: string) => price.split('$')[1]

export const removeSpecialChars = (string: string, regex = /[^a-zA-Z \s0-9,'\-#.*&]/g) => `${string}`.replace(regex, '')

export const getCookieByName = (name, cookiesFromRequest?: string) => {
  let cookies: string
  if (globalThis?.document) {
    cookies = globalThis?.document.cookie ?? ''
  } else {
    cookies = cookiesFromRequest ?? ''
  }
  if (cookies) {
    const cookieName = cookies.split(';')?.find((cookie) => cookie.includes(name)) ?? ''
    return cookieName?.split('=')?.[1]
  }
  return ''
}

// IDs for each variant within an experiment are numeric and indexed at 0
export const DEFAULT_CHECKOUT_VARIANT = '0'

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

export const getWindowAgent = (nextContextReq: NextIncomingMessage | undefined) => {
  // Ref: https://stackoverflow.com/questions/59494037/how-to-detect-the-device-on-react-ssr-app-with-next-js
  // if you are on the server and you get a 'nextContextReq' property from your context
  if (nextContextReq) {
    // get the user-agent from the headers
    return nextContextReq.headers['user-agent']
  }
  // if you are on the client you can access the navigator from the window object
  return navigator.userAgent
}
